// src/components/display/dialog/Dialog.tsx

"use client";
import React, { useEffect } from "react";
import useDialog from "./dialog-context-provider";

const Dialog = () => {
  const { dialog, closeDialog } = useDialog();

  // Confirm the dialog on "Enter" key pressed
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === "Enter") {
        const target = e.target as HTMLElement;
        if (target && (target.tagName === "INPUT" || target.tagName === "TEXTAREA")) {
          // Do not trigger the dialog action if the user is typing in an input or textarea
          return;
        }
        dialog.handleButtonRight(e);
        closeDialog();
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [dialog, closeDialog]);

  if (!dialog.open) {
    return null;
  }

  return (
    <div className="fixed inset-0 flex justify-center items-center backdrop-blur-sm z-30">
      <div className="flex flex-col bg-white p-9 rounded-xl shadow-md max-w-xl w-full">
        <h3 className="font-normal text-lg">{dialog.headline}</h3>
        <div className="py-3 font-light">
          {dialog.content()} {/* Ensure content is called as a function */}
        </div>
        <div className="flex justify-end gap-1">
          {/* Cancel Button */}
          {dialog.CancelButtonText && (
            <button
              onClick={(e) => {
                e.preventDefault();
                if (dialog.handleCancel) {
                  dialog.handleCancel(e);
                }
                closeDialog();
              }}
              className="btn btn-outline btn-error"
            >
              {dialog.CancelButtonText}
            </button>
          )}
          {/* Left Button */}
          {dialog.buttonLeftText && (
            <button
              onClick={(e) => {
                e.preventDefault();
                dialog.handleButtonLeft(e);
                closeDialog();
              }}
              className="btn btn-outline btn-primary"
            >
              {dialog.buttonLeftText}
            </button>  
          )}
          
          {/* Right Button */}
          {dialog.buttonRightText && (
            <button
              onClick={(e) => {
                e.preventDefault();
                dialog.handleButtonRight(e);
                closeDialog();
              }}
              className="btn btn-outline btn-secondary"
            >
              {dialog.buttonRightText}
            </button>  
          )}
          
        </div>
      </div>
    </div>
  );
};

export default Dialog;
