"use client";
import Image from "next/image";
import walletImg from "../../../../public/bot-min.png";
import Menu from "./Menu";
import Link from "next/link";

export default function Navbar() {
  const imageHeight = 90;

  return (
    <div className="navbar flex justify-between px-2 gap-1 bg-base-100 border-b-2 border-base-200 md:min-h-[80px] min-h-[100px]">
      <Link
        href={"/"}
        className="flex flex-col normal-case text-xl px-0"
      >
        <div className="flex flex-col sm:flex-row gap-0 md:gap-1 items-center">
          <Image
            src={walletImg}
            alt="Crypto trading bot Logo"
            height={imageHeight}
            className="rounded-3xl"
            priority={false}
            quality={75}
          />
          <div>
            <span className="text-lg font-light">crypto-trading-bot</span>
          </div>
        </div>
      </Link>
      <div className="flex-none gap-1">
        <Menu />
      </div>
    </div>
  );
}
