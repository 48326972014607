import { FC, useEffect, useState } from "react";
import { faBars,faSignOut, faCirclePlay, faStream, faHome, faClockRotateLeft, faEdit, faProcedures, faMicrochip, } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Link from "next/link";
import { signOut, useSession } from "next-auth/react";
import SignInButton from "../../../components/inputs/signin-button";

const Menu: FC = ({ }) => {
  const { data: session, update } = useSession();

  useEffect(() => {
    update();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
    {session ? (
      <div className="dropdown dropdown-end z-30">
        <label
          tabIndex={0}
          className="btn btn-outline btn-primary btn-md drawer-button"
          //onClick={() => setIsOpen(!isOpen)}
        >
          <FontAwesomeIcon icon={faBars} size="1x" />
        </label>

        <ul
          tabIndex={0}
          className="menu menu-md dropdown-content md:mt-4 mt-6 rounded-b-xl shadow bg-base-100 w-52 z-90"
        >
          <li>
                <div className="flex items-between gap-4">
                  <Link href="/"><FontAwesomeIcon icon={faHome} /><span className="ml-3">Home</span></Link>
                </div>
          </li>
          <li>
                <div className="flex items-between gap-4">
                  <Link href="/processor"><FontAwesomeIcon icon={faMicrochip} /><span className="ml-3">Signal Processors</span></Link>
                </div>
          </li>
          <li>
                <div className="flex items-between gap-4">
                  <Link href="/live"><FontAwesomeIcon icon={faStream} /><span className="ml-3">Live Views</span></Link>
                </div>
          </li>
          <li>
                <div className="flex items-between gap-4">
                  <Link href="/backtest"><FontAwesomeIcon icon={faClockRotateLeft} /><span className="ml-3">Backtests</span></Link>
                </div>
          </li>
          <li>
                <div className="flex items-between gap-4">
                  <Link href="/editor"><FontAwesomeIcon icon={faEdit} /><span className="ml-3">Editor</span></Link>
                </div>
          </li>
          <li>
                <div className="flex items-between gap-4">
                  <button className="" onClick={() => signOut()}>
                    <FontAwesomeIcon icon={faSignOut} />
                    <span className="ml-3">Log out</span>
                  </button>
                </div>
          </li>
          <li className="p-0">
            <div className="pl-3"></div>
          </li>
        </ul>
      </div>
      ) : (
        <SignInButton />
      )}
    </>
  
  );
};

export default Menu;
