"use client";
import React from "react";
import { signIn } from "next-auth/react";

const SignInButton = () => {
  return (
    <div>
      <button className="btn btn-outline btn-primary" onClick={() => signIn()}>
        Sign in
      </button>
    </div>
  );
};

export default SignInButton;
