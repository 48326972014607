import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Roboto\",\"arguments\":[{\"subsets\":[\"latin\",\"latin-ext\"],\"weight\":[\"100\",\"300\",\"400\",\"500\",\"700\"]}],\"variableName\":\"roboto\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@fortawesome/fontawesome-svg-core/styles.css");
;
import(/* webpackMode: "eager", webpackExports: ["ToastContainer"] */ "/app/node_modules/react-toastify/dist/react-toastify.esm.mjs");
;
import(/* webpackMode: "eager" */ "/app/src/app/globals.css");
;
import(/* webpackMode: "eager" */ "/app/node_modules/react-toastify/dist/ReactToastify.css");
;
import(/* webpackMode: "eager", webpackExports: ["DialogProvider"] */ "/app/src/components/display/dialog/dialog-context-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/display/dialog/Dialog.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/header/navigation/Navbar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/session-provider.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/styles/custom-toast.css");
