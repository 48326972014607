"use client";
import {
  createContext,
  useState,
  ReactNode,
  useContext,
  ReactElement,
  useRef,
} from "react";

export interface DialogState {
  open?: boolean;
  headline: string;
  content: () => ReactNode;
  buttonLeftText?: string;
  handleButtonLeft: (e: any) => void;
  buttonRightText?: string;
  handleButtonRight: (e: any) => void;
  buttonLeftData?: any;
  buttonRightData?: any;
  CancelButtonText?: string;
  handleCancel?: (e: any) => void;
}

const initialState: DialogState = {
  open: false,
  headline: "headline",
  content: () => <span>content</span>,
  buttonLeftText: "buttonLeftText",
  handleButtonLeft: () => {},
  buttonRightText: "buttonRightText",
  handleButtonRight: () => {},
  buttonLeftData: {},
  buttonRightData: {},
  CancelButtonText: undefined,
  handleCancel: undefined,
};

interface DialogContextProps {
  dialog: DialogState;
  openDialog: (config: Omit<DialogState, 'open'>) => void;
  closeDialog: () => void;
}

const DialogContext = createContext<DialogContextProps>({
  dialog: initialState,
  openDialog: () => {},
  closeDialog: () => {},
});

interface DialogProviderProps {
  children: ReactNode;
}

export const DialogProvider = ({ children }: DialogProviderProps) => {
  const [dialog, setDialog] = useState<DialogState>(initialState);

  const openDialog = (config: Omit<DialogState, 'open'>) => {
    setDialog({
      ...config,
      open: true,
    });
    console.log("Dialog opened");
  };

  const closeDialog = () => {
    setDialog((prev) => ({
      ...prev,
      open: false,
    }));
  };

  return (
    <DialogContext.Provider value={{ dialog, openDialog, closeDialog }}>
      {children}
    </DialogContext.Provider>
  );
};

const useDialog = () => useContext(DialogContext);

export default useDialog;